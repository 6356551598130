<template>
  <div>
    <v-tabs dark background-color="primary">
      <v-tab
        v-for="item in items"
        :key="item.name"
        :to="{ name: item.name }"
      >
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <br>
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    items () {
      const { items } = this.$store.state.menu
      const { children = [] } = items.find(({ name }) => name === 'WhatDoWeTreat')
      return children.map(({ name, meta }) => ({
        name,
        label: meta.label
      }))
    }
  }
}
</script>
